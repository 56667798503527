.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.centralized-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.centralized-div {
    width: 800px;
    height: 600px;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    padding: 5px;
    z-index: 1;
}

body {
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    height: 100vh;
}

nav {
    background-color: #333;
    color: #fff;
    padding: 10px;
    position: sticky;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

li {
    margin-right: 20px;
}

.menu-button {
    font-size: 18px;
    background-color: #333;
    color: #fff;
    border: none;
    padding: 5px 8px 5px 7px;
    cursor: pointer;
}

.submenu-button {
    font-size: 18px;
    background-color: #555;
    color: #fff;
    border: none;
    padding: 7px 7px;
    cursor: pointer;
}

button:hover {
    background-color: #555;
}

button.active {
    background-color: #555;
}

h2 {
    color: #333;
}

ul.submenu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #555;
    margin-top: -5px;
    margin-left: 15px;
    z-index: 1000;
}

ul.submenu li {
    margin: 0;
}

.submenu-button:hover {
    background-color: #777;
}

button {
    font-size: 18px;
    background-color: #567ebb;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0px 2px 0px 2px;
}

input[type="checkbox"] {
    width: 28px;
    height: 28px;
}

.finish-button {
    background-color: #ebae56;
}

.remove-button {
    background-color: #cc5545;
}

.close-button {
    background-color: #606d80;
}

.finish-button:hover {
    background-color: #c0392b;
}

select, input[type="text"], input[type="password"], input[type="number"], input[type="file"], textarea {
    padding: 10px;
    margin: 0px 2px 0px 2px;
    box-sizing: border-box;
    border: 1px solid #3498db;
    border-radius: 5px;
}

img {
    margin: 0px 2px 0px 2px;
    box-sizing: border-box;
    border-radius: 5px;
}

input[type="text"]:focus, input[type="password"]:focus, textarea:focus {
    margin: 0px 2px 0px 2px;
    outline: none;
    border: 1px solid #2980b9;
}

.input-container {
    position: relative;
}

.input-container button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border: none;
    background: none;
    color: #9d9c9c;
    cursor: pointer;
    font-size: 18px;
}

.input-container input[type="text"] {
    width: 100%;
    padding-right: 5px;
}

table {
    margin-top: 5px;
    border-collapse: collapse;
    overflow-x: auto;
    overflow-y: auto;
    display: block;
}

th, td {
    padding: 2px 2px;
    text-align: left;
    min-height: 41px;
}

th {
    position: sticky;
    top: 0;

}


tr {
    transition: background-color 0.5s;
    background-color: transparent;
    min-height: 41px;
}

tr:hover {
    background-color: #f0f0f0;
}

.search-button {
    background-color: #339966;
    padding: 8px 10px;
    margin: 0px 2px 0px 2px;
}

.clean-button {
    background-color: #606d80;
}

.add-button {
    background-color: #ffffff;
    padding: 8px 10px;
    margin: 0px 2px 0px 2px;
}

.back-button {
    background-color: #b0b0b0;
}

.shorcut-button {
    font-size: 30px;
    margin: 10px;
    width: 300px;
}


.total-label {
    color: #2b4c7e;
    font-size: 25px;
    font-weight: bold;
}

.header-label {
    color: #000000;
    font-size: 23px;
    font-weight: bold;

}

.centralized-div1 {
    left: 50%;
    margin: 0px auto;
    border-radius: 10px;
    height: calc(100svh - 100px);
}

.centralized-div-content {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

@media (min-width: 500px) {
    .centralized-div1 {
        width: 500px;
    }
}

:root {
    --error-color: #f28c8c;
    --warning-color: #f5e386;
    --normal-green-color: #a8e6cf;
    --normal-color: #fff;
    height: 100%;
}

.datepicker {
    width: 110px;
}

.button-plus, .button-minus {
    font-size: 20px;
    padding: 5px 10px;
    width: 40px;
    height: 40px;
}

/*tr:nth-of-type(even) td {*/
/*    background-color: #f2f2f2;*/
/*}*/

/*tr:nth-of-type(odd) td {*/
/*    background-color: #ffffff;*/
/*}*/

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: fixed;
    top: calc(15%);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    width: 300px;
    max-width: 300px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}