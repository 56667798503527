.previous-next-button {
    width: 40px;
    height: 40px;
    padding: 2px;
    border: none;
    font-size: 1em;
    cursor: pointer;
    align-items: center;
    justify-content: center;

}

.toggle-button {
    width: 30px;
    height: 30px;
    padding: 2px;
    border: none;
    font-size: 1em;
    cursor: pointer;
    transition: transform 0.7s ease;
    align-items: center;
    justify-content: center;
}

.toggle-button.rotate {
    transform: rotate(90deg);
}

.table-container {
    transition: opacity 0.7s ease;
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.table-container.fade-in {
    opacity: 1;
    height: auto;
    overflow: visible;
}

.table-container.fade-out {
    opacity: 0;
    height: 0;
    overflow: hidden;
}
